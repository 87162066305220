<template>
    <div class="container" ref="container">
        <Toast />
        <div style="padding: 10px 0;"  class="clearfix">
            <div class="btn-group pull-left clearfix">
                <button @click="position='left'" type="button" class="btn btn-info"><i class="fa fa-toggle-left"></i></button>
                <button @click="position='middle'" type="button" class="btn btn-info"><i class="fa fa-align-justify"></i></button>
                <button @click="position='right'" type="button" class="btn btn-info"><i class="fa fa-toggle-right"></i></button>
            </div>

            <div class="pull-left" style="margin-left: 15px">
                {{report?.id}}/ {{report?.patient_name}}/ {{report?.patient_age}}/ {{report?.patient_gender}} / <b style="text-transform: uppercase;">{{report?.test_type}}</b>
                <div>
                    {{report?.history}}
                </div>
            </div>

            <div class="pull-right">
                <!-- <select @change="selectTemplate" class="form-control">
                    <option disabled selected value="">Select Template</option>
                    <option v-for="tp in templates" :key="tp" :value="tp.content">{{tp.title}}</option>
                </select> -->
                <AutoComplete v-model="template" :dropdown="true" placeholder="Select Template" :suggestions="filteredTemplates" @item-select="selectTemplate" @complete="searchTemplates($event)" field="title" />
            </div>
            <div v-if="report?.status == 'pending'" class="pull-right" style="margin: 8px;" :style="{color: report?.timer < '00:05:00' ? 'red' : 'inherit'}">
                <b>{{report?.timer}}</b>
            </div>
        </div>
        
        <div class="clearfix"></div>
        <div class="row">
            <div :style="{display: position == 'left' ? 'none' : 'block'}" :class="{'col-sm-6': position=='middle', 'col-sm-12': position == 'right'}">
                <div style="width: 80px; float: left;">
                    <div style="margin-bottom: 4px;" v-for="image in allImages" :key="image">
                        <img v-if="image.isPdf" style="max-width: 100%; cursor: pointer;" @click="showImage=image" src="../../assets/pdf.png" alt="">
                        <img v-else @click="showImage=image" style="max-width: 100%; cursor: pointer;" :src="apiOrigin+'/images/cardiology/'+image.file" alt="">
                    </div>
                </div>
                <div style="margin-left: 90px;">
                    <embed v-if="showImage?.isPdf" :src="apiOrigin+'/images/cardiology/'+showImage.file" style="width: 100%; height: 675px;" type="application/pdf">
                    <img style="display: block; margin: auto; max-width: 100%" :src="apiOrigin+'/images/cardiology/'+showImage.file" alt="">
                </div>
            </div>
            <div  :style="{display: position == 'right' ? 'none' : 'block'}" :class="{'col-sm-6': position=='middle', 'col-sm-12': position == 'left'}">
                <ckeditor style="background: #fff; min-height: 300px;" :editor="editor" :config="editorConfig" v-model="editorData"  @ready="onReady"></ckeditor>
                <div style="padding: 10px 0;">
                    <div  class="pull-right">
                        <button @click="publishReport" type="button" class="btn btn-success btn-lg"><i class="fa fa-paper-plane"></i> Publish</button>
                    </div>
                    <button @click="draftReport" style="margin-right: 10px;" type="button" class="btn btn-primary">Save Draft</button>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-12" style="padding-top: 10px;">
                <div  class="pull-right">
                    <button @click="draftReport" style="margin-right: 10px;" type="button" class="btn btn-primary">Save Draft</button>
                    <button @click="publishReport" type="button" class="btn btn-primary">Publish</button>
                </div>
            </div>
        </div> -->
        
    </div>
</template>

<script>
    // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
    import Toast from 'primevue/toast';
    import AutoComplete from 'primevue/autocomplete';

    export default {
        components: {
            Toast,
            AutoComplete
        },
        name: 'app',
        data() {
            return {
                editor: DecoupledEditor, //ClassicEditor,
                editorData: '',
                editorConfig: {},
                report: null,

                position: 'middle',

                allImages: [],
                showImage: "",

                templates: [],
                filteredTemplates: [],
                template: null,
            };
        },
        methods: {
            onReady( editor )  {
				// document.body.prepend( editor.ui.view.toolbar.element );
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
			},
            searchTemplates(e){
                this.filteredTemplates = this.templates.filter(t=>t.title.toLowerCase().indexOf(e.query.toLowerCase()) != -1)
            },
            selectTemplate(){
                if(this.template) this.editorData = this.template.content
            },
            async draftReport(){
                let r = await this.post('cardiologist/draft-report', {
                    report: this.editorData,
                    report_id: this.$route.params['report_id'],
                });
                if(r.success){
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Report drafted successfully', life: 3000});
                }else{
                    if(this.report.status == 'pending'){
                        this.$router.push("/cardiologist/reports/pending");
                    }else{
                        this.$router.push("/cardiologist/reports/review");
                    }
                }
            },
            async publishReport(){
                let r = await this.post('cardiologist/publish-report', {
                    report: this.editorData,
                    report_id: this.$route.params['report_id'],
                });
                if(r.success){
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Report published successfully', life: 3000});
                    // this.$router.push("/cardiologist/reports/pending");
                    if(this.report.status == 'pending'){
                        this.$router.push("/cardiologist/reports/pending");
                    }else{
                        this.$router.push("/cardiologist/reports/review");
                    }
                }else{
                    this.$router.push("/cardiologist/reports/pending");
                }
            }
        },
        async mounted(){
            let timerIncreased = false;
            let w = ()=>{
                if(!document.body.contains(this.$refs.container)) return;

                if(this.report?.accepted_at){
                    let t1 = new Date(this.report.accepted_at);
                    t1.setTime(t1.getTime()+30*60*1000);
                    let t2 = new Date();
                    let td = t1.getTime()-t2.getTime();
                    td = td/1000;
                    if(td < 0) td = 0;
                    let m = String(Math.floor(td/60));
                    if(m.length == 1) m = '0'+m;
                    let s = String(Math.floor(td%60));
                    if(s.length == 1) s = '0'+s;

                    this.report.timer = '00:'+m+':'+s;

                    if(this.report.status != 'review' && this.report.timer < "00:02:00" && !timerIncreased){
                        timerIncreased = true;
                        if(confirm("Your reporting timer is about to expire, do you want to extend for 10 more minutes?")){
                            this.post("cardiologist/increase-timer", {report_id: this.report.id}).then(r=>{
                                if(r.success){
                                    this.report.accepted_at = r.accepted_at;
                                    this.$toast.add({severity:'success', summary: 'Success', detail:'Timer increased successfully', life: 3000});
                                }else{
                                    this.$toast.add({severity:'error', summary: 'Failed', detail:'Failed to increase timer', life: 3000});
                                }
                            });
                        }
                    }


                    if(this.report.timer == '00:00:00'  && this.report.status == 'pending' && this.report.assign_type == 'all'){
                        this.$router.push("/cardiologist/reports/pending");
                    }
                }
                setTimeout(w,1000);
            }
            w();
            this.post("cardiologist/load-report", {
                report_id: this.$route.params['report_id'],
            }).then(res=>{
                if(res.success){
                    this.report = res.report;
                    this.editorData = this.report.report ? this.report.report : '';
                    this.allImages = this.report.images.split(",");

                    for(let i in this.allImages){
                        this.allImages[i] = {
                            file: this.allImages[i],
                            isPdf: this.allImages[i].indexOf(".pdf") != -1
                        }
                    }

                    this.showImage = this.allImages[0];                    
                }else{
                    this.$router.push("/cardiologist/reports/pending");
                }
            });

            this.templates = await this.get("cardiologist/load-ecg_templates");
        }
    }
</script>
<style scoped>

</style>